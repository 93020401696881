const { Timer } = require('easytimer.js/dist/easytimer.min.js');

const examTimer = function() {
  var init = function(selector) {
    var seconds = $(selector).data('seconds');
    var timer = new Timer();

    var timeFormat = function() {
      let format = $(selector).data('time-format')
      return format === 'quiz' ? ['minutes', 'seconds'] : ['hours', 'minutes', 'seconds']
    }

    timer.addEventListener('secondsUpdated', function (e) {
      $(selector).html(timer.getTimeValues().toString(timeFormat()))
    });

    timer.addEventListener('targetAchieved', function (e) {
      onTimeout($(selector));
    });

    timer.start({countdown: true, startValues: { seconds: seconds }});
  };

  var onTimeout = function($timerEl) {
    var resUrl = $timerEl.data('time-out-url');

    if ($timerEl.data('time-out-modal')) {
      var $modal = $($timerEl.data('time-out-modal'));

      $modal.on('hidden.bs.modal', function (e) {
        location.href = resUrl
      });

      $modal.modal('show');
    } else {
      setTimeout(function(){ location.reload(); }, 2000);
    }
  };

  return { initFor: init }
}();

export default examTimer

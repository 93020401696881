

$(document).on('change', '.type-checker', function(event) {
  let exerciseType = $("option:selected", this).data('is-extra-options-visible')

  if (!!exerciseType) {
    $('.hidden-section').removeClass('d-none')
  } else {
    $('.hidden-section').addClass('d-none')
  }
})
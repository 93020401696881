$(function() {
  $(".mark-as-read").on("click", function() {
    let checked;

    if ($(this).is(':checked')) {
      checked = true;
    } else {
      checked = false;
    }

    const body = new FormData();
    body.append('material_id', this.parentElement.previousElementSibling.value);
    body.append('read', checked);

    fetch("/student_material/mark_as_read", {
      method: "POST",
      body: body,
      credentials: "include",
      headers: new Headers({
        "x-csrf-token": document.getElementsByName('csrf-token')[0].content
      }),
      redirect: "follow"
    })
    .then(response => {
      if(response.redirected) {
        location.href = response.url;
      }
    });
  });
});
$(function() {
  let params = new URLSearchParams(location.search);
  let urParts = location.href.split('#');
  if (urParts.length > 1) {
    materialId = urParts[1];
    if ($("#" + materialId).length > 0) {
      $("#" + materialId).show();
    }
  }

  $(".material-block-name").on("click", function() {
    material = this.parentElement.parentElement.parentElement.nextElementSibling;
    if($(material).is(':hidden')) {
      this.parentElement.nextElementSibling.style.display = "block";
      this.parentElement.nextElementSibling.nextElementSibling.style.display = "none";
      showMaterial(material);
    } else {
      this.parentElement.nextElementSibling.style.display = "none";
      this.parentElement.nextElementSibling.nextElementSibling.style.display = "block";
      hideMaterial(material);
    }
  });

  $(".material-block-name-read").on("click", function() {
    this.parentElement.parentElement.nextElementSibling.style.display = "block"

    this.parentElement.nextElementSibling.getElementsByClassName('material-block-hide')[0].style.display = "block"
    this.parentElement.nextElementSibling.getElementsByClassName('material-block-show')[0].style.display = "none"
  });

  $(".material-block-show").on("click", function() {
    $(this).hide();
    $(this).siblings(".material-block-hide").show();
    material = this.parentElement.parentElement.nextSibling.nextSibling;
    showMaterial(material);
  });

  $(".material-block-hide").on("click", function() {
    $(this).hide();
    $(this).siblings(".material-block-show").show();
    material = this.parentElement.parentElement.nextSibling.nextSibling;
    hideMaterial(material);
  });

  if(params.get('show')) {
    let element = this.getElementById(`show-${params.get('show')}`);

    element.style.display = "block";
    element.previousElementSibling.getElementsByClassName('material-block-show')[0].style.display = "none";
    element.previousElementSibling.getElementsByClassName('material-block-hide')[0].style.display = "block";
  }

  function showMaterial(material) {
    $('.material-block').hide();
    material.style.display = "block";
    newUrl = location.href.split('#')[0] + '#' + material.id;
    window.history.pushState("", "", newUrl);
  }

  function hideMaterial(material) {
    material.style.display = "none";
    parentId = material.parentElement.id;
    newUrl = location.href.split('#')[0] + '#' + parentId;
    window.history.pushState("", "", newUrl);
  }
});

import editorInitializer from '../ckeditor';
import '../vendor/nested_form';

var imageUploadParams = function(el) {
  let $form = $(el).parents('form')
  let imageUploadUrl = $form.find('[name=image_upload_url]').val();
  let csrf = $form.find('[name=authenticity_token]').val();
  return { imageUpload: { url: imageUploadUrl, csrf: csrf } };
}

var multiEditor = function() {
  var init = function(selector) {
    $(selector).each(function(_, el){
      editorInitializer.initFor(el, imageUploadParams(el));
    });
  };

  return { initFor: init }
}()


$(document).ready(function() {
  let inputWithMask = document.querySelector('.regexp-mask')

  if (!!inputWithMask) {
    IMask(
      $(inputWithMask),
      {
        mask: /^[0-1]{0,1600}$/
      }
    );
  }
});

$(document).ready(function() {
  let $questionForm = $('.admin-question-form');

  if($questionForm.length > 0) {
    multiEditor.initFor('.with-editor');
  };
});

$(document).on('nested:fieldAdded', function(event){
  let field = event.field;
  let el = field.find('.with-editor')[0];
  editorInitializer.initFor(el, imageUploadParams(el));
})

$(document).ready(function() {
  let libraryTopicIdParam = new URLSearchParams(window.location.search)

  if(libraryTopicIdParam.has('library_topic_id')) {
    $(".link-to-library").show();
    $("#linkCheck").prop("checked", true)
  }

  $("#linkCheck").on("click", function() {
    if(this.checked) {
      $(".link-to-library").show();
    } else {
      $(".link-to-library").hide();
      $('#general_topic').val('')
      $('#topic').val('')
      $('#subtopic').val('')
      $('#question_library_topics_id').val('')
    }
  });
});

$(document).on('change', '.question-topic', function(event) {
  let selectedTopic = $("option:selected", this).val()
  let selectedCourseType = $(".question-course option:selected").data('exercise-type')
  let selectedCourseId = $(".question-course option:selected").val()

  if (!!selectedTopic) {
    let params = new URLSearchParams(location.search);
    let refreshURL = $("#refresh_path").val()

    params.set('topic_id', selectedTopic)
    params.set('exercise_type', selectedCourseType)

    let url = refreshURL.concat('?', params.toString())

    if (selectedCourseId !== getCookie('course_id')) {
      window.history.replaceState({}, '', url)
      window.location.href = url
    }
  }
});

$(document).ready(function() {
  $('[data-updatable]').each(function() {
    const $target = $(this);
    const updatable = $target.data('updatable');
    const $emitter = $(updatable.by);

    function updateOptions(options) {
      $target.html(options);
      $target.trigger('change');
    }

    $emitter.on('change', function() {
      const params = {};
      const options = ['<option value=""></option>'];

      params[updatable.filter_name] = $(this).val();

      $(this).val() ?
        $.getJSON(updatable.url, params, data => {
          data['select_options'].map(function(el){
            options.push(`<option value="${el.value}">${el.name}</option>`);
          });

          updateOptions(options);
        })
      : updateOptions(options);
    });
  });
});

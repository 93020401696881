// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import 'bootstrap/dist/js/bootstrap';
import 'scripts/updatable_select';
import 'scripts/material';
import 'scripts/admin_users/question_form';
import 'scripts/users/registration_form';
import 'scripts/admin_users/course_form';
import 'scripts/admin_users/image_uploader';
import 'scripts/material_read';
import examTimer from 'scripts/exam_timer';
import 'scripts/charts';
import IMask from 'imask';
import dragula from 'dragula';

const images = require.context('../images', true)

window.jQuery = $;
window.dragula = dragula;

$(document).ready(function() {
  $('[data-js-href]').on('click', function(e) {
    var href = $(e.currentTarget).data('js-href');
    if(href) { location.href = href }
  });

  $(".alert.auto-hide").delay(3000).slideUp(200, function() {
    $(this).alert('close');
  });

  $('.submit-on-change').on('change', function(){
    $(this).closest('form').submit();
  });

  $('.sidebar').on('click', 'a[href^="#"]', function(e){
    var $sidebar = $(e.delegateTarget);
    var $collapser = $sidebar.find('[data-toggle="collapse"]');
    $collapser.trigger('click');
  })

  window.onpopstate = function (event) {
    var anchor = window.location.hash;
    $('[href*="#"]').removeClass('active');
    $('[href="' + anchor + '"]').addClass('active');
  }

  examTimer.initFor('.exam-timer');

  window.getCookie = function(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }
});


$('.domain-navigation').on('click', 'a[data-domain]', function(e) {
  let url = window.location.protocol + '//'+ e.target.dataset.domain + (window.location.port ? ':' + window.location.port: '')
  if (!!url) {
    window.location.href = url
  }
});

var drake = dragula({
  isContainer: function (el) {
    return el.classList.contains('dragula-container');
  }
});

drake.on('drop', function(el, target){
  if(el.rowIndex != -1 ){
    send_by_ajax(
      '/admin/set_position',
      'POST',
      { 'model_class': el.getAttribute('data-kind'),
        'id': el.getAttribute('data-record_id'),
        'new_position': el.rowIndex + 1
      }
    )
    location.reload();
  }
});

const send_by_ajax = (url, method, params={}) => {
  $.ajax({
    dataType: 'script',
    url: url,
    type: method,
    data: $.param(params),
    beforeSend: function() {
      return true
    },
    error: function(jqXHR, textStatus, errorThrown) {
      alert('HTTP ERROR: ' + jqXHR.status);
    }
  })
};

const delay = (function() {
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();

$(document).on('keyup', '.search-form', function() {
  var el = document.getElementsByClassName('search-form')[0]
  var search_keyword = el.value
  var url = el.getAttribute('data-search_url')
  delay(function () {
    if(search_keyword.length >= 3 ){
      location.replace(encodeURI(url + '?search=' + search_keyword))
    }
    else if(search_keyword.length == 0 ){
      location.replace(url)
    }
  }, 700);
  document.getElementsByClassName('search-form')[0].value = search_keyword;
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

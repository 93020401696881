require('./vendor/ckeditor5-build-custom/build/ckeditor');

const editorInitializer = function() {
  var config = function(opts) {
    let toolbar = [
      'bold', 'italic', 'bulletedList', 'numberedList', '|',
      'imageUpload', 'insertTable', 'link', '|',
      'undo', 'redo'
    ];

    let config = { toolbar: toolbar, link: { addTargetToExternalLinks: true } };

    if(opts.imageUpload) {
      let uploadOpts = {
        simpleUpload: {
          uploadUrl: opts.imageUpload.url,
          headers: { 'X-CSRF-TOKEN': opts.imageUpload.csrf }
        }
      }
      Object.assign(config, uploadOpts)
    }

    return config;
  };

  var init = function(el, opts) {
    ClassicEditor.create(el, config(opts))
      .then(function(editor) { })
      .catch(function(error) { console.log(error) })
  };

  return { initFor: init }
}();

export default editorInitializer

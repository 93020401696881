import Chart from 'chart.js';

const Charts = function(){
  var $el;

  var examScore = function(selector) {
    $el = $(selector);
    var chartData = $el.data('graph-data');

    var gridlines = {
      display: true,
      color: '#B4B2AD'
    }

    var chartData = $el.data('graph-data');

    var data = {
      datasets: [{
        data: chartData.data,
        borderColor: '#24C590',
        borderWidth: 2,
        pointRadius: 1,
        pointBackgroundColor: '#24C590',
        fill: false
      }]
    };

    new Chart($el, {
      type: 'line',
      data: data,
      options: {
        aspectRatio: 1,
        legend: { display: false },
        scales: {
          xAxes: [{
            type: 'time',
            distribution: 'linear',
            autoSkip: true,
            gridLines: gridlines,
            time: { unit: chartData.time_unit },
            ticks: {
              fontColor: '#B4B2AD',
              min: chartData.min_date,
              max: chartData.max_date
            }
          }],
          yAxes: [{
            position: 'right',
            gridLines: gridlines,
            ticks: {
              fontColor: '#B4B2AD',
              stepSize: 10,
              suggestedMin: chartData.min_score,
              suggestedMax: chartData.max_score
            }
          }]
        }
      }
    });
  };

  var readyness = function(selector) {
    $el = $(selector);
    var chartData = $el.data('graph-data');

    new Chart($el, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 0,
          data: [
            chartData.correct,
            chartData.incorrect,
            chartData.unanswered
          ],
          backgroundColor: [
            '#24C590',
            '#FE4A49',
            '#FFFFFF'
          ]
        }]
      },
      options: {
        tooltips: { enabled: false },
        legend: { display: false },
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI
      }
    });
  }

  return { examScore: examScore, readyness: readyness };
}();

$(document).ready(function() {
  if ($('#exam-score-chart').length > 0) {
    Charts.examScore($('#exam-score-chart'));
  }

  if ($('.readyness-chart').length > 0) {
    $('.readyness-chart').each(function() {
      Charts.readyness($(this));
    })
  }
});

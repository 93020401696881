$(document).ready(function() {
  $("#linkCheck").on("click", function() {
    if(this.checked) {
      $(".promocode-field").show();
      $('.promocode-field input:text').attr('disabled', false);
    } else {
      $(".promocode-field").hide();
      $('.promocode-field input:text').attr('disabled', true);
    }
  });

  if($("#linkCheck").is(':checked')) {
    $(".promocode-field").show();
  } else {
    $(".promocode-field").hide();
  }
});
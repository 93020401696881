function readURL(input, section) {
  if (input.files && input.files[0]) {
    let reader = new FileReader()

    reader.onload = function(event) {
      let $img = $(`<div class='square-box'><img src="${event.target.result}"></div>`)
      $(section).html($img)
    }

    reader.readAsDataURL(input.files[0])
  }
}

$(document).on('change', '.uploader', function(event) {
  let $section = $(this).closest('.form-group').find('.image-preview')
  readURL(this, $section)
});
